export function pushDataLayer(data, cb) {
  if (process.env.NODE_ENV === 'development') {
    console.log(
      `%ctrack: ${JSON.stringify(data)}`,
      'background: #333; color: #F6AF25;'
    )
  }
  window.dataLayer = window.dataLayer || []
  if (typeof cb === 'function') data.eventCallback = cb
  window.dataLayer.push(data)
}

export function gapv(path, page, title, cb) {
  path = path || window.location.pathname
  const url = `${path}${page}`
  const data = {
    event: 'GAPV',
    VirtualPageURL: url,
    VirtualPageTitle: title,
  }
  pushDataLayer(data, cb)
}

export function gaev(category, action, label, value, cb) {
  const data = {
    event: 'GAEV',
    EventCategory: category,
    EventAction: action,
    EventLabel: label,
    EventValue: value,
  }
  pushDataLayer(data, cb)
}

export function gatiming(category, variable, value, label, cb) {
  const data = {
    event: 'GATiming',
    TimingCategory: category,
    TimingVar: variable,
    TimingValue: value,
    TimingLabel: label,
  }
  pushDataLayer(data, cb)
}
