import BaseLayout from '@/layouts/base'

export default {
  component: () => import(/* webpackChunkName: "login" */ './LoginApp'),
  meta: {
    layout: () => BaseLayout,
    title: 'User Login',
  },
  children: [
    {
      name: 'LoginForm',
      path: '',
      props: ({ query }) => ({
        next: query.next,
        popup: Boolean(query.popup),
      }),
      component: () => import(/* webpackChunkName: "login" */ './LoginForm'),
    },
  ],
}
