<template>
  <div :style="{ cursor, width, height }">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Stage',
  inject: ['$dubee'],
  props: {
    cursor: {
      type: String,
      default: 'grab',
    },
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '100%',
    },
  },
  mounted() {
    const { stage } = this.$dubee
    stage.mount(this.$el)
    stage.animate()
  },
}
</script>
