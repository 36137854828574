import Vue from 'vue'
import VueRouter from 'vue-router'

import demo from './pages/demo/routes.js'
import editor from './pages/editor/routes.js'
import login from './pages/login/routes.js'
import publicDemo from './pages/public-demo/routes.js'
import readyplayer from './pages/readyplayer/routes.js'
import scene from './pages/scene/routes.js'
import scriptEditor from './pages/script-editor/routes.js'

import { prefixRoutes, prefixRoute } from './utils/prefixRoutes.js'

Vue.use(VueRouter)

const routes = [
  prefixRoute('/login', login),

  { path: '/', redirect: '/trial' },
  prefixRoute('/trial', publicDemo),

  ...prefixRoutes('/demo', demo),
  ...prefixRoutes('/editor', editor),
  ...prefixRoutes('/script-editor', scriptEditor),

  prefixRoute('/readyplayer', readyplayer),
  prefixRoute('/scene', scene),
]

const router = new VueRouter({ routes })

router.beforeEach(async (to, from, next) => {
  const routeTitle = to.matched[0]?.meta?.title
  document.title = [routeTitle, 'GliaStar'].filter(Boolean).join(' - ')
  next()
})

export default router
