import EmptyLayout from '@/layouts/empty'

export default {
  name: 'scene',
  path: ':id',
  component: () => import(/* webpackChunkName: "scene" */ './SceneApp'),
  props: true,
  meta: {
    layout: () => EmptyLayout,
    title: 'scene',
  },
}
