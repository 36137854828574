export default {
  component: () => import(/* webpackChunkName: "public-demo" */ './PublicDemo'),
  meta: {
    title: 'Try GliaStar for Free',
  },
  children: [
    {
      name: 'PublicDemoHome',
      path: '',
      component: () =>
        import(/* webpackChunkName: "public-demo" */ './PublicDemoHome'),
      meta: {
        nextRouteName: 'Steps',
      },
    },
    {
      path: '',
      component: () =>
        import(/* webpackChunkName: "public-demo" */ './PublicDemoSteps'),
      children: [
        {
          name: 'Steps',
          path: 'steps',
          redirect: 'select-character',
        },
        {
          name: 'SelectCharacter',
          path: 'select-character',
          component: () =>
            import(
              /* webpackChunkName: "public-demo" */ './step1-SelectCharacter/SelectCharacter'
            ),
          meta: {
            title: 'Select your character',
            prevRouteName: 'PublicDemoHome',
            nextRouteName: 'InputText',
            preview: {
              componentName: 'DubeePreview',
              enableControls: true,
              enablePlayControls: false,
            },
          },
        },
        {
          name: 'InputText',
          path: 'input-text',
          component: () =>
            import(
              /* webpackChunkName: "public-demo" */ './step2-InputText/InputText'
            ),
          meta: {
            title: 'Insert your message',
            prevRouteName: 'SelectCharacter',
            nextRouteName: 'SelectTemplate',
            preview: {
              componentName: 'DubeePreview',
              enableControls: false,
              enablePlayControls: true,
            },
          },
        },
        {
          name: 'SelectTemplate',
          path: 'select-template',
          component: () =>
            import(
              /* webpackChunkName: "public-demo" */ './step3-SelectTemplate/SelectTemplate'
            ),
          meta: {
            title: 'Select your video template',
            prevRouteName: 'InputText',
            nextRouteName: 'PublicDemoFinish',
            preview: {
              componentName: 'UserVideoPreview',
              enableControls: false,
              enablePlayControls: true,
            },
          },
        },
      ],
    },
    {
      name: 'PublicDemoFinish',
      path: 'finish',
      component: () =>
        import(/* webpackChunkName: "public-demo" */ './PublicDemoFinish'),
    },
  ],
}
