import * as THREE from 'three'

import Dubee from './Dubee'

export { default as debug } from './debug'

export default function install(Vue, { assetsRoot } = {}) {
  Vue.component('Dubee', Dubee)
  Vue.prototype.$dubee = Vue.$dubee = {
    assetsRoot: assetsRoot || '/api/character/',
    DataUrl: (...args) => Vue.$dubee.assetsRoot + args.join('/'),
  }

  window.THREE = THREE
}
