export default [
  {
    path: '',
    redirect: {
      name: 'Editor',
      params: {
        character: 'dubee',
      },
    },
  },
  {
    name: 'Editor',
    path: ':character',
    component: () => import(/* webpackChunkName: "editor" */ './EditorApp'),
    props: true,
    meta: {
      title: 'Editor',
    },
    redirect: (to) => {
      const params = to.params
      if (!params.character) params.character = 'dubee'
      return { name: 'InsertMessage', params }
    },
    children: [
      {
        name: 'InsertMessage',
        path: 'insert-message',
        component: () =>
          import(/* webpackChunkName: "editor" */ './step1-InsertMessage'),
      },
      {
        name: 'CreateScript',
        path: 'create-script',
        component: () =>
          import(/* webpackChunkName: "editor" */ './step2-CreateScript'),
      },
    ],
  },
]
