<template>
  <Stage :width="width" :height="height">
    <slot />
  </Stage>
</template>

<script>
import Stage from './Stage'

export default {
  name: 'Dubee',
  components: {
    Stage,
  },
  inheritAttrs: false,
  props: {
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '100%',
    },
  },
}
</script>
