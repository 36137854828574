export const LangType = Object.freeze({
  Zh: 'zh-hant',
  Ja: 'ja',
  Ko: 'ko',
  En: 'en',
  Vi: 'vi',
  Id: 'id',
  Th: 'th',
  Yu: 'yue',
})

export const Languages = [
  {
    value: LangType.Zh,
    name: '繁體中文',
    model: 'general-cn',
  },
  {
    value: LangType.Ja,
    name: '日本語',
    model: 'general-jp',
  },
  { value: LangType.Ko, name: '한국어', model: 'general-ko' },
  {
    value: LangType.En,
    name: 'English',
    model: 'general-en',
  },
  {
    value: LangType.Vi,
    name: 'Tiếng Việt',
    model: 'general-vn',
  },
  {
    value: LangType.Id,
    name: 'Bahasa Indonesia',
    model: 'general-en',
  },
  {
    value: LangType.Th,
    name: 'ภาษาไทย',
    model: 'general-th',
  },
  {
    value: LangType.Yu,
    name: '粵語',
    model: 'general-cn',
  },
]
