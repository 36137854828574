export function prefixRoute(prefix, { path, ...options }) {
  return {
    path: [prefix, path].filter(Boolean).join('/'),
    ...options,
  }
}

export function prefixRoutes(prefix, routes) {
  return routes.map((route) => prefixRoute(prefix, route))
}
