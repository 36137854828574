import * as Sentry from '@sentry/vue'
import DubeePlugin from 'dubee'
import Vue from 'vue'

import * as constants from './constants'
import DefaultLayout from './layouts/default'
import Track from './plugins/Track'
import router from './router'
import { characterBaseURL } from './settings'
import store from './store'
import i18n from './utils/i18n'

Vue.prototype.$const = constants

Vue.use(Track)

Vue.use(DubeePlugin, {
  assetsRoot: characterBaseURL,
})

Vue.config.productionTip = false
function createApp(componentOption) {
  return new Vue(componentOption)
}

const version = process.env.TAG

if (version) {
  Sentry.setTag('version', version)
  Sentry.init({
    Vue,
    environment: version.includes('a')
      ? 'alpha'
      : version.includes('b')
      ? 'beta'
      : 'production',
    dsn:
      'https://51bb699d15f4db234b674daeeb73b307@o4507453182967808.ingest.us.sentry.io/4507649748303872',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      /^https:\/\/gstudio\.gliacloud\.com/,
      /^https:\/\/.*---genv-studio-al23s7k26q-de\.a\.run\.app/,
      // /^http:\/\/localhost(:\d+)?/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

createApp({
  i18n,
  router,
  store,
  render(h) {
    const matchedRoute = this.$route.matched[0]
    if (!matchedRoute) return null
    const getLayout = matchedRoute.meta?.layout
    const Layout = getLayout ? getLayout(this) : null
    return h(Layout || DefaultLayout)
  },
}).$mount('#app')
