import EmptyLayout from '@/layouts/empty'

export default [
  {
    path: '',
    redirect: {
      name: 'Demo',
      params: {
        character: 'dubee',
      },
    },
  },
  {
    name: 'Demo',
    path: ':character/:uid?',
    component: () => import(/* webpackChunkName: "demo" */ './DemoApp'),
    props: ({ params, query }) => ({
      ...params,
      embed: Boolean(query.embed),
    }),
    meta: {
      layout: (context) => (context.$route.query.embed ? EmptyLayout : null),
      title: 'Demo',
    },
  },
]
