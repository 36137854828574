export default [
  {
    path: '',
    redirect: {
      name: 'ScriptEditor',
      params: {
        character: 'dubee',
      },
    },
  },
  {
    name: 'ScriptEditor',
    path: ':character',
    component: () =>
      import(/* webpackChunkName: "script-editor" */ './ScriptEditorApp'),
    meta: {
      title: 'Script Editor',
    },
    props: true,
  },
]
