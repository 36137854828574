import { LangType } from '@/constants'
import Cookies from 'js-cookie'
import Vue from 'vue'
import VueI18n from 'vue-i18n'

import EN from '../locale/en.json'
import JA from '../locale/ja.json'
import ZH from '../locale/zh-tw.json'
const COOKIE_KEY = 'language'

Vue.use(VueI18n)

function detectLanguage() {
  const cookieLang = Cookies.get(COOKIE_KEY)
  if (cookieLang) return cookieLang

  const language = navigator.languages
    ? navigator.languages[0]
    : navigator.language || navigator.userLanguage

  switch (language.toLowerCase()) {
    case 'ja':
      return LangType.Ja
    case 'en':
    case 'en-us':
      return LangType.En
    case 'ko':
    case 'ko-kr':
      return LangType.Ko
    default:
      return LangType.Zh
  }
}

export function setLanguage(lang) {
  i18n.locale = lang
  Cookies.set(COOKIE_KEY, lang, { expires: 365 })
}

const i18n = new VueI18n({
  locale: detectLanguage(),
  missing: (_, key) => key,
  messages: {
    [LangType.Zh]: ZH,
    [LangType.Ja]: JA,
    [LangType.En]: EN,
  },
})

export default i18n
